import { Geolocation, GeolocationAction, GeolocationActions } from "@/types/geolocation";

const geolocationReducer = (state: Geolocation, action: GeolocationAction): Geolocation => {
    switch (action.type) {
        case GeolocationActions.SET_DATA: {
            return {
                country: action.country,
                currency: action.currency,
                ipAddress: action.ipAddress,
                city: action.city,
                postalCode: action.postalCode,
                region: action.region,
            };
        }
    }
    throw Error("Unknown geolocation action: " + action.type);
};

export default geolocationReducer;
