import { TagManager } from "@/utils/trackers";

export const enum GAEvents {
    Purchase = "purchase",
    ViewCart = "view_cart",
    BeginCheckout = "begin_checkout",
    SignUp = "sign_up",
    Gender = "web_onboarding_gender",
}

export const sendMetrics = (...args: Parameters<(typeof TagManager)["track"]>) => {
    TagManager.track(...args);
};
