import React, { lazy, LazyExoticComponent } from "react";

export interface LazyComponentWithPreload<
    T extends React.ComponentType<any> = React.ComponentType<any>,
> extends LazyExoticComponent<T> {
    preload: (images?: string[]) => void;
}

export const lazyWithPreload = <T extends React.ComponentType<any>>(
    factory: () => Promise<{ default: T }>
): LazyComponentWithPreload<T> => {
    const Component = lazy(factory) as LazyComponentWithPreload<T>;
    Component.preload = (images = []) => {
        factory();
        images.forEach((src) => {
            const image = new Image();
            image.src = src;
        });
    };
    return Component;
};
