import {
    createContext,
    memo,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
} from "react";

import { useGender } from "./GenderProvider";

export const enum Themes {
    Male = "male",
    Female = "female",
}

export interface ThemeContextType {
    theme: Themes;
    setTheme: (theme: Themes) => void;
    isLightTheme: boolean;
}

export const ThemeContext = createContext<ThemeContextType>({
    setTheme: () => null,
    theme: Themes.Male,
    isLightTheme: false,
});

const ThemeProvider = ({ children }: PropsWithChildren) => {
    const { isFemale } = useGender();

    const setTheme = useCallback((theme: Themes) => {
        document.body.classList.remove(theme === Themes.Male ? Themes.Female : Themes.Male);
        document.body.classList.add(theme);
    }, []);

    const theme = useMemo(() => (isFemale ? Themes.Female : Themes.Male), [isFemale]);

    const isLightTheme = useMemo(() => theme === Themes.Female, [theme]);

    useEffect(() => {
        if (isFemale) {
            setTheme(Themes.Female);
        } else {
            setTheme(Themes.Male);
        }
    }, [isFemale, setTheme]);

    return (
        <ThemeContext.Provider
            value={{
                setTheme,
                theme,
                isLightTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    return useContext(ThemeContext);
};

export default memo(ThemeProvider);
