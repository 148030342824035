import { getFunnelTypeForAnalytics } from "@/utils/analyticsFunnelType";
import { defaultGender } from "@/utils/GenderProvider";
import { IOSVersionDetector } from "@/utils/IOSVersionDetector";
import { localStorageManager, LocalStorageManagerKeys } from "@/utils/localStorageManager";
import { AmplitudeTracker, PaltaDataPlatformTracker } from "@/utils/trackers";
import { EventProps } from "@/utils/trackers/amplidute";

export const VIEW_EVENT = "web_onboarding_screen_viewed";
export const RESULT_EVENT = "web_onboarding_screen_result";
export const PURCHASE_COMPLETED = "web_purchase_completed";
export const PAYWALL_SHOWN = "web_paywall_shown";
export const WEB_BUTTON_CLICK = "web_button_click";

export const logAnalyticsEvent = (evt: string, evtProps?: EventProps) => {
    if (evtProps) {
        AmplitudeTracker.track(evt, evtProps);
        PaltaDataPlatformTracker.track(evt, evtProps);
    } else {
        AmplitudeTracker.track(evt);
        PaltaDataPlatformTracker.track(evt);
    }
};

export const logSingleSourceAnalyticsEvent = (evt: string, evtProps?: EventProps) => {
    AmplitudeTracker.track(evt, evtProps);
};

export const logPaltaAnalyticsEvent = (evt: string, evtProps: EventProps) => {
    PaltaDataPlatformTracker.track(evt, evtProps);
};

export const setAnalyticsUserProperties = (props: EventProps) => {
    AmplitudeTracker.setAmplitudeProperties(props);
    PaltaDataPlatformTracker.setAmplitudeProperties(props);
};

export const setSingleSourceAnalyticsUserProperties = (props: EventProps) => {
    AmplitudeTracker.setAmplitudeProperties(props);
};

export const setPaltaAnalyticsUserProperties = (props: EventProps) => {
    PaltaDataPlatformTracker.setAmplitudeProperties(props);
};
export const setUserId = () => {
    const storedUserId = localStorageManager.getStoredItem<string>(
        LocalStorageManagerKeys.USER_UUID
    );
    if (storedUserId) {
        AmplitudeTracker.setUserId(storedUserId);
        PaltaDataPlatformTracker.setUserId(storedUserId);
    }
};

const setDefaultUserProperties = (isPalta?: boolean) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let properties: EventProps = {
        ...params,
        web_version: process.env.npm_package_version,
    };

    if (!window.location.pathname.includes("both")) {
        properties = {
            ...properties,
            web_domain: window.location.hostname,
            web_funnel_flow: getFunnelTypeForAnalytics(defaultGender),
            web_gender: defaultGender,
            web_ios_version: IOSVersionDetector.getIOSVersion(),
        };
    }
    setUserId();
    if (isPalta) {
        setPaltaAnalyticsUserProperties(properties);
    } else {
        setSingleSourceAnalyticsUserProperties(properties);
    }
};

setDefaultUserProperties();
setDefaultUserProperties(true);
