export const enum QUESTIONS_IDS {
    PRIMARY_GOAL = "primary_goal",
    HOW_OLD = "how_old",
    BIGGEST_DRIVE = "biggest_drive",
    FITNESS_EXPERIENCE = "fitness_experience",
    OBSTACLES = "obstacles",
    PROMO_HELP = "promo_help",
    PROMO_ZING_AI = "promo_zing_ai",
    PROMO_REVIEWS = "promo_reviews",
    PUSH_UPS = "push_ups",
    SQUATS = "squats",
    CLIMBING_STAIRS = "climbing_stairs",
    TRAIN_EXPERIENCE = "train_experience",
    OTHER_SPORTS = "other_sports",
    EQUIPMENT_BY_ENVIRONMENT = "equipment_by_environment",
    TARGET_AREAS = "target_areas",
    LOCATION = "location",
    HEALTH_PROBLEMS = "health_problems",
    BODY_TYPE = "body_type",
    DESIRED_BODY = "desired_body",
    CURRENT_WEIGHT = "current_weight",
    BODY_FAT = "body_fat",
    AI_BODY_SCAN = "ai_body_scan",
    EVENT_MOTIVATION = "event_motivation",
    WEB_CYCLE_DATE = "web_cycle_date",
    WEB_CYCLE = "web_cycle",
    WEB_REPRODUCTIVE_STAGE = "web_reproductive_stage",
    WEB_PERIOD_DAYS = "web_period_days",
    WEB_CYCLE_DAYS = "web_cycle_days",
    WEB_CYCLE_SYMPTOMS = "web_cycle_symptoms",
    WEB_CYCLE_PHASE_CHART = "web_cycle_phase_chart",
    MOTIVATION_1 = "motivation_screen_1",
    MOTIVATION_2 = "motivation_screen_2",
    MOTIVATION_3 = "motivation_screen_3",
    CIRCULAR_PROMO = "circular_promo",
    REACH_GOAL_REWARD = "web_reach_goal_reward",
    REACH_GOAL_YOURSELF = "web_reach_goal_yourself",
    CHANGE_FACE_PROMO = "promo_change_face",
    TARGET_WEIGHT = "target_weight",
    DIET_TYPE = "nutrition_book_diet_type",
    PROMO_SCREEN_PLANS = "promo_screen_plans",
    PROMO_SCREEN_FITNESS = "promo_screen_fitness",
    PROMO_SCREEN_TRIPLE = "promo_screen_triple",
    PROMO_SCREEN_FEMALE_UNIQUE = "promo_screen_female_unique",
    PROMO_SCREEN_WORKOUT_SMARTLY = "promo_screen_workout_smartly",
    PROMO_SCREEN_BIG_CHANGES = "promo_screen_big_changes",
    PROMO_SCREEN_REPRODUCTIVE_STAGE = "promo_reproductive_stage",
    ACTIVITY_LEVEL = "activity_level",
    SLEEP_AMOUNT = "sleep_amount",
    PROMO_PLANS_FIT_48 = "promo_plans_fit_48",
    PROMO_PLANS_FIT_67 = "promo_plans_fit_67",
    PROMO_PLANS_FIT_74 = "promo_plans_fit_74",
    PROMO_PLANS_FIT_88 = "promo_plans_fit_88",
    FITNESS_LEVEL = "fitness_level",
    TRAIN_3_MONTHS = "train_3months",
    ACTIVITY_ENGAGED = "activity_engaged",
    EATING_HABITS = "eating_habits",
    TRAINING_TRIGGER = "training_trigger",
    AGE = "age",
    HEIGHT = "height",
    EVENT_DATE = "event_date",
    GYM_TYPE = "gym_type",
    WORK_SCHEDULE = "work_schedule",
    BEST_SHAPE = "best_shape",
    TRIED_PILATES = "tried_pilates",
    FLEXIBILITY = "flexibility",
    LOSE_BREATH = "lose_breath",
    TRAINING_FREQUENCY = "current_training_frequency",
    WALKS_FREQUENCY = "walks_frequency",
    WATER_CONSUMPTION = "water_consumption",
    ENERGY_LEVEL = "energy_level",
    BREAKFAST = "breakfast",
    LUNCH = "lunch",
    DINNER = "dinner",
    WEIGHT_GAIN_EVENT = "weight_gain_event",
    BMI_RESULTS = "bmi_results",
    FEEL_BETWEEN_MEALS = "feel_between_meals",
    SUCCESS_MEASURE = "success_measure",
    TRACK_STRENGTH = "track_strength",
    HOPE_TO_FEEL = "hope_to_feel",
    IMPORTANT_ELEMENTS = "important_elements",
    PROTECT_AGAINST_AGING = "protect_against_aging",
    SPORT_PREFER = "sport_prefer",
    DESIRED_WORKOUT_TIME = "desired_workout_time",
    CORTISOL = "level_of_cortisol_&_testosterone",
    WEIGHT_DEAD_LIFT = "weight_dead_lift",
    WEIGHT_BENCH_PRESS = "weight_bench_press",
    MOTIVATION = "motivation",
    PREVENT_EXERCISE_CONSISTENTLY = "prevent_exercise_consistently",
    ABILITY_TO_STAY_MOTIVATED = "ability_to_stay_motivated",
    ACHIEVE_FITNESS_GOAL = "achieve_fitness_goal",
    MOTIVATE_MOST = "motivate_most",
    COPE_STRESS = "cope_stress",
    DAILY_WALK = "daily_walk",
    PLAN_FAILED = "plans_failed",
    STATEMENT_REQUIRE_GUIDANCE = "statement_require_guidance",
    STATEMENT_SUPPORT_BOOSTS_MOTIVATION = "statement_support_boosts_motivation",
    STATEMENT_FALL_BACK = "statement_fall_back",
    STATEMENT_HARD_TO_STICK = "statement_hard_to_stick",
    STATEMENT_BUSY_SCHEDULE = "statement_busy_schedule",
    WEIGHT_LOSS_GOAL = "weight_loss_goal",
    PACE = "pace",
    PROMO_SOCIAL_PROOF = "promo_social_proof",
    BREATH_LOSE = "breath_lose",
    // Weight Predict
    INITIAL_WEIGHT_PREDICT = "initial_weight_predict",
    FIRST_WEIGHT_PRREDICT_DATE = "first_weight_predict_date",
    WEIGHT_PRREDICT_DATE = "weight_predict_date",
    INTERMEDIATE_WEIGHT_LOSS = "intermediate_weight_loss",
    INTERMEDIATE_WEIGHT_LOSS_DATE = "intermediate_weight_loss_date",
    SHOW_INTERMEDIATE_RESULT = "show_intermidiate_result",

    TOO_EXPENSIVE_FITNESS_PROGRAM_FEE = "too_expensive_fitness_program_fee",
    SEDENTARY_LIFESTYLE = "sedentary_lifestyle",
    WEIGHT_LOSS_REVIEW = "weight_loss_review",
}

export const enum QuestionTypes {
    AIBodyScan = "AIBodyScan",
    YesNo = "yes/no",
    PromoCircular = "circularPromo",
    BmiPromoMale = "bmiPromoMale",
    BmiPromoFemale = "bmiPromoFemale",
    Radio = "radio",
    RadioCards = "radioCards",
    Checkbox = "checkbox",
    CheckboxInline = "checkbox_inline",
    CheckboxImageLeft = "checkbox_image_left",
    RadioImageLeft = "radio_image_left",
    PromoHelp = "promoHelp",
    PromoScreen = "promoScreen",
    Input = "input",
    Cortisol = "cortisol_screen",
    Range = "range",
    Date = "date",
    Rate = "rate",
    ReviewsPromo = "reviewsPromo",
    RangeNumber = "rangeNumber",
    PromoCyclePhase = "promoCyclePhase",
    PromoZingAi = "promoZingAi",
    PromoSocialProof = "promoSocialProof",
    InitialWeightPredict = "initialWeightPredict",
    CurrencyInput = "currencyInput",
    CalisthenicsFeedbackLoops = "calisthenicsFeedbackLoops",
    ReviewWithLoader = "reviewWithLoader",
}
