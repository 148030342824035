import { useEffect } from "react";

export const ALLOW_X_SWIPE_CLASS_NAMES = ["zing-allow-x-swipe"];
export const ALLOW_Y_SWIPE_CLASS_NAMES = ["overflow-auto", "overflow-y-scroll"];
export const BLOCK_Y_SWIPE_CLASS_NAMES = ["zing-block-y-swipe"];

const THRESHOLD_X = 5; // px
const THRESHOLD_Y = 5; // px
const THRESHOLD = 10; // px

const shouldAllowScroll = (element: Element | null, classNames: string[]) => {
    // Traverse the DOM hierarchy to check if any parent element allows scrolling
    while (element) {
        if (classNames.some((className) => element?.classList?.contains(className))) {
            return true;
        }
        element = element.parentElement;
    }
    return false;
};

export const useBlockHorizontalSwipe = () => {
    useEffect(() => {
        let startX: number | null = null;
        let startY: number | null = null;

        const handleTouchStart = (event: TouchEvent) => {
            startX = event.touches[0].clientX;
            startY = event.touches[0].clientY;
        };

        const handleTouchMove = (event: TouchEvent) => {
            const currentX = event.touches[0].clientX;
            const currentY = event.touches[0].clientY;

            const deltaX = Math.abs(currentX - Number(startX));
            const deltaY = Math.abs(currentY - Number(startY));

            const targetElement = event.target as Element;
            const isAllowSwipeX = shouldAllowScroll(targetElement, ALLOW_X_SWIPE_CLASS_NAMES);
            const isBlockSwipeX = shouldAllowScroll(targetElement, BLOCK_Y_SWIPE_CLASS_NAMES);
            const isAllowSwipeY = true;

            if (isBlockSwipeX) {
                event.preventDefault();
                return;
            }

            if (deltaX > THRESHOLD_X && deltaY < THRESHOLD && !isAllowSwipeX) {
                event.preventDefault();
                return;
            }

            if (deltaY > THRESHOLD_Y && deltaX < THRESHOLD && !isAllowSwipeY) {
                event.preventDefault();
                return;
            }
        };

        document.addEventListener("touchstart", handleTouchStart, { passive: true });
        document.addEventListener("touchmove", handleTouchMove, { passive: false });

        return () => {
            document.removeEventListener("touchstart", handleTouchStart);
            document.removeEventListener("touchmove", handleTouchMove);
        };
    }, []);
};
