import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";

import App from "@/App";
import { BaseProviders } from "@/components/BaseProviders";
import { imagesToPreload } from "@/constants/imagesToPreload";
import { IS_FEMALE } from "@/constants/isFemale";
import { preloadImages } from "@/hooks/usePreloadImg";
import { addReloadingListener } from "@/utils/infrastructure";

import "./localization/i18next";
import "@/localization/i18next";

import "./index.css";

addReloadingListener();

const root = createRoot(document.getElementById("root") || document.body);

// TODO: the COMPONENTS.FIRST_LOADER is used here. Must not be imported as a lot of dependencies being loaded
preloadImages(imagesToPreload(IS_FEMALE)["FirstLoader"] || []);

root.render(
    <StrictMode>
        <Suspense fallback={null}>
            <BaseProviders>
                <App />
            </BaseProviders>
        </Suspense>
    </StrictMode>
);
