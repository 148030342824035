import React, {
    createContext,
    memo,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useMatch } from "react-router-dom";

import { setAnalyticsUserProperties } from "@/constants/amplitude";
import { IS_FEMALE } from "@/constants/isFemale";
import { Routes as AppRoutes } from "@/constants/routes";
import { Genders } from "@/types/gender";

import { getFunnelTypeForAnalytics } from "./analyticsFunnelType";

const FEMALE_PREFIX = "female";
const MALE_PREFIX = "man";

export interface GenderContextType {
    gender: Genders;
    setGender: React.Dispatch<React.SetStateAction<Genders>>;
    isFemale: boolean;
    prefix: string;
}

export const defaultGender = IS_FEMALE ? Genders.Female : Genders.Male;
const defaultPrefix = IS_FEMALE ? FEMALE_PREFIX : MALE_PREFIX;

export const GenderContext = createContext<GenderContextType>({
    gender: defaultGender,
    setGender: () => null,
    isFemale: IS_FEMALE,
    prefix: defaultPrefix,
});

const GenderProvider = ({ children }: PropsWithChildren) => {
    const isFriends = useMatch({
        path: `/:both/${AppRoutes.Friends}`,
        end: false,
    });

    const isOfferUrl = useMatch({
        path: `/${AppRoutes.Both}/${AppRoutes.Offer}`,
        end: false,
    });

    const genderPathData = useMatch({
        path: isOfferUrl
            ? `/${AppRoutes.Both}/${AppRoutes.Offer}/*`
            : isFriends
              ? `/:both/${AppRoutes.Friends}/*`
              : `/:both/*`,
        end: true,
    });

    const defaultGenderByPath = genderPathData?.params["*"] as Genders;

    const [gender, setGender] = useState<Genders>(defaultGenderByPath || defaultGender);

    const isFemale = useMemo(() => gender === Genders.Female, [gender]);
    const prefix = useMemo(
        () => (gender === Genders.Female ? FEMALE_PREFIX : MALE_PREFIX),
        [gender]
    );

    useEffect(() => {
        const gender = genderPathData?.params["*"] as Genders;
        if (gender) {
            let domain = window.location.hostname;
            isFriends && (domain = `${domain}/${AppRoutes.Friends}`);

            setAnalyticsUserProperties({
                web_domain: domain,
                web_funnel_flow: getFunnelTypeForAnalytics(gender),
                web_gender: gender,
            });
        }
    }, [isFriends, setGender, genderPathData]);

    return (
        <GenderContext.Provider
            value={{
                gender,
                setGender,
                isFemale,
                prefix,
            }}
        >
            {children}
        </GenderContext.Provider>
    );
};

export const useGender = () => {
    return useContext(GenderContext);
};

export default memo(GenderProvider);
