const getIOSVersion = () => {
    const userAgent = window.navigator.userAgent;
    const match = userAgent.match(/OS (\d+)_?(\d+)?_?(\d+)?/);

    if (match) {
        const versionParts = match.slice(1).map((part) => parseInt(part, 10));
        const version = parseFloat(versionParts.join("."));
        return isNaN(version) ? null : version;
    }

    return null;
};

export const IOSVersionDetector = {
    getIOSVersion,
};
