import { memo, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";

import { Routes as AppRoutes } from "@/constants/routes";
import useConsent from "@/hooks/useConsent";
import { useLocalization } from "@/hooks/useLocalization";
import { lazyWithPreload } from "@/utils/lazyPreload";
import { FlowTypes } from "@/utils/providers/types";

import { APP_CONTAINER_ID } from "./constants/elementsIds";
import { useBlockHorizontalSwipe } from "./hooks/useSwipe";

import "./App.css";

const Router = lazyWithPreload(() => import("@/components/Router"));
const LoaderPromo = lazyWithPreload(() => import("@/components/Promo/LoaderPromo"));
const MainFlow = lazyWithPreload(() => import("@/MainFlow"));

const App = () => {
    const appContainerRef = useRef<HTMLDivElement | null>(null);

    useBlockHorizontalSwipe();
    useLocalization();
    useConsent();

    const changeContainerHeight = () => {
        let height = window.innerHeight;

        if (window.visualViewport) {
            height = window.visualViewport.height;
        }

        const appContainerRef = document.getElementById(APP_CONTAINER_ID);
        if (appContainerRef) {
            appContainerRef.style.cssText = `--zing-window-height: ${height}px`;
        }
    };

    useEffect(() => {
        changeContainerHeight();
        // TODO: implement debounce here
        window.addEventListener("resize", changeContainerHeight);
        window.visualViewport?.addEventListener("resize", changeContainerHeight);

        return () => {
            window.removeEventListener("resize", changeContainerHeight);
            window.visualViewport?.removeEventListener("resize", changeContainerHeight);
        };
    }, []);

    return (
        <div
            ref={appContainerRef}
            id={APP_CONTAINER_ID}
            className="zing-screen overflow-auto scroll-smooth font-outfit-zing"
        >
            <Routes>
                <Route path="/" element={<MainFlow />} />
                <Route
                    path={AppRoutes.Calisthenics}
                    element={<MainFlow flowType={FlowTypes.Calisthenics} />}
                />
                <Route path={AppRoutes.Funnel} element={<MainFlow />} />
                <Route
                    path={AppRoutes.WeightLoss}
                    element={<MainFlow flowType={FlowTypes.WeightLoss} />}
                />
                <Route path="*" element={<Router />} />
                <Route path={AppRoutes.Loader} element={<LoaderPromo onLoaded={() => null} />} />
            </Routes>
            <div
                id={"preload-checkout-container-paypal"}
                className={"palta-payment-container hidden h-0"}
            ></div>
            <div
                id={"preload-checkout-container-card"}
                className={"palta-payment-container hidden h-0"}
            ></div>
            <div
                id={"preload-checkout-container-apple"}
                className={"palta-payment-container hidden h-0"}
            ></div>
        </div>
    );
};

export default memo(App);
