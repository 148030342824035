export abstract class Tracker {
    _isInitialized = false;
    _isStoped = false;

    abstract init(): void;

    stop() {
        if (this._isInitialized) {
            this._isStoped = true;
        }
    }
}
