import { FemaleBodyFatRangeTypes } from "@/constants/bodyMetrics";
import curvy from "@/female/assets/body-type/curvy.webp";
import extra from "@/female/assets/body-type/extra.webp";
import fit from "@/female/assets/body-type/fit.webp";
import midSized from "@/female/assets/body-type/mid-sized.webp";
import muscular from "@/female/assets/body-type/muscular.webp";
import plump from "@/female/assets/body-type/plump.webp";
import slim from "@/female/assets/body-type/slim.webp";
import thin from "@/female/assets/body-type/thin.webp";
import toned from "@/female/assets/body-type/toned.webp";
import femaleAverage from "@/female/assets/female-body-pic-by-id/average.png";
import femaleBulk from "@/female/assets/female-body-pic-by-id/bulk.png";
import femaleCut from "@/female/assets/female-body-pic-by-id/cut.png";
import femaleExtraBulk from "@/female/assets/female-body-pic-by-id/extra-bulk.png";
import femaleLean from "@/female/assets/female-body-pic-by-id/lean.webp";
import femaleMuscular from "@/female/assets/female-body-pic-by-id/muscular.webp";
import femaleExtra from "@/female/assets/female-body-pic-by-id/overweight.png";
import femaleRipped from "@/female/assets/female-body-pic-by-id/ripped.webp";
import femaleSkinny from "@/female/assets/female-body-pic-by-id/skinny.png";
import femaleSlightly from "@/female/assets/female-body-pic-by-id/slightly.png";

export const enum BodyTypes {
    Muscular = "muscular",
    Slim = "slim",
    MidSize = "mid-sized",
    Plump = "plump",
    Extra = "extra",
    Thin = "thin",
    Lean = "lean",
    Fit = "fit",
    Toned = "toned",
    Curvy = "curvy",
    Same = "same",
    Smaller = "smaller",
    Ripped = "ripped",
}

export const enum BackendBodyTypes {
    Skinny = "skinny",
    Regular = "regular",
    Muscular = "muscular",
    Extra = "extra",
    SlightlyExtra = "slightly_extra",
    Ripped = "ripped",
    Lean = "lean",
    Bulk = "bulk",
    ExtraBulk = "extra_bulk",
    Cut = "cut",
}

export const picByBodyType: Record<Partial<BodyTypes>, string> = {
    [BodyTypes.Muscular]: muscular,
    [BodyTypes.Slim]: slim,
    [BodyTypes.MidSize]: midSized,
    [BodyTypes.Plump]: plump,
    [BodyTypes.Extra]: extra,
    [BodyTypes.Thin]: thin,
    [BodyTypes.Fit]: fit,
    [BodyTypes.Toned]: toned,
    [BodyTypes.Curvy]: curvy,
    [BodyTypes.Same]: "",
    [BodyTypes.Ripped]: "",
    [BodyTypes.Lean]: thin,
    [BodyTypes.Smaller]: "",
};

export const mapBodyFatToBodyType: Record<FemaleBodyFatRangeTypes, BackendBodyTypes> = {
    [FemaleBodyFatRangeTypes["10-14"]]: BackendBodyTypes.Skinny,
    [FemaleBodyFatRangeTypes["15-19"]]: BackendBodyTypes.Skinny,
    [FemaleBodyFatRangeTypes["20-24"]]: BackendBodyTypes.Regular,
    [FemaleBodyFatRangeTypes["25-29"]]: BackendBodyTypes.Regular,
    [FemaleBodyFatRangeTypes["30-34"]]: BackendBodyTypes.SlightlyExtra,
    [FemaleBodyFatRangeTypes["35-39"]]: BackendBodyTypes.SlightlyExtra,
    [FemaleBodyFatRangeTypes["40-44"]]: BackendBodyTypes.Extra,
    [FemaleBodyFatRangeTypes[">45"]]: BackendBodyTypes.Extra,
};

export const femaleBodyPicByBodyId = {
    [BackendBodyTypes.Skinny]: femaleSkinny,
    [BackendBodyTypes.Regular]: femaleAverage,
    [BackendBodyTypes.SlightlyExtra]: femaleSlightly,
    [BackendBodyTypes.Extra]: femaleExtra,
    cut: femaleCut,
    bulk: femaleBulk,
    extra_bulk: femaleExtraBulk,
    lean: femaleLean,
    [BackendBodyTypes.Muscular]: femaleMuscular,
    ripped: femaleRipped,
};
