import amplitudeOld from "amplitude-js";

import { AMPLITUDE_KEY, PALTA_DATA_PLATFORM_KEY } from "@/constants/env";
import { IS_PROD_ENV } from "@/constants/env";
import { WebEventTypes } from "@/constants/event";
import { removeUrlProtocol } from "@/utils/removeUrlProtocol";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

import { Tracker } from "./common";

export type EventProps = {
    [key: string]: amplitude.Types.ValidPropertyType | undefined | null;
};

const PALTA_DATA_PLATFORM_INSTANCE_NAME = "palta_data_platform";
const DEFUALT_TELEMETRY_API =
    process.env.REACT_APP_TELEMETRY || "telemetry.zing.paltabrain.com/v1/amplitude";
const PALTA_API = window.location.pathname.includes("/funnel")
    ? process.env.REACT_APP_FUNNEL_TELEMETRY || DEFUALT_TELEMETRY_API
    : DEFUALT_TELEMETRY_API;
const SESSION_REPLAY_COVERAGE = IS_PROD_ENV ? 0.01 : 1; // prod: only 10% of sessions will be captured
const INIT_SESSION_REPLAY_FROM_SCREEN_NUMBER = 2;

const sessionReplayTracking = sessionReplayPlugin({ sampleRate: SESSION_REPLAY_COVERAGE });

class AmplitudeInstance extends Tracker {
    private readonly _name: string = "";
    private readonly _key: string | undefined = undefined;
    private readonly _apiEndpoint: string | undefined = undefined;

    private _propertiesQueue: EventProps = {};
    private _eventsQueue: { event: string; eventProps?: EventProps }[] = [];
    private _instance: amplitude.Types.BrowserClient | undefined;
    private _screenViewEventCount = 0;
    private _uuid: string | null = null;
    private _isSessionReplayInitialized = false;

    constructor(key: string, name?: string, apiEndpoint?: string) {
        super();

        this._key = key;
        this._name = name ?? "default_tracking";
        this._apiEndpoint = apiEndpoint;
    }

    init() {
        if (this._key && !this._isInitialized) {
            const serverUrl = this._apiEndpoint
                ? {
                      serverUrl: this._apiEndpoint,
                  }
                : {};

            this._instance = amplitude.createInstance();

            this._instance
                .init(this._key, "", {
                    instanceName: this._name,
                    ...serverUrl,
                    autocapture: false,
                })
                .promise.then(() => {
                    this._isInitialized = true;
                    this.proceedPropertiesQueue();
                    this.proceedEventsQueue();
                    this._instance?.setUserId(this._uuid || "");
                });
        }

        if (this._isStoped) {
            this._isStoped = false;
        }
    }

    createIdentify(props: EventProps) {
        const identify = new amplitude.Identify();

        Object.entries(props).forEach(([key, value]) => {
            value && identify?.set(key, value);
        });

        return identify;
    }

    setAmplitudeProperties(props: EventProps) {
        if (this._isInitialized && !this._isStoped) {
            const identify = this.createIdentify(props);
            this._instance?.identify(identify);
        } else {
            this._propertiesQueue = {
                ...this._propertiesQueue,
                ...props,
            };
        }
    }

    track(event: string, eventProps?: EventProps) {
        if (this._isInitialized && !this._isStoped) {
            this._instance?.track(event, eventProps);

            if (event === WebEventTypes.VIEW_EVENT) {
                this._screenViewEventCount++;
            }

            this.initSessionReplay(eventProps?.screen as string);
        } else {
            this._eventsQueue.push({ event, eventProps });
        }
    }

    setUserId(uuid: string) {
        if (this._instance) {
            this._instance?.setUserId(uuid);
        } else {
            this._uuid = uuid;
        }
    }

    private proceedPropertiesQueue() {
        this.setAmplitudeProperties(this._propertiesQueue);
    }

    private proceedEventsQueue() {
        this._eventsQueue.forEach(({ event, eventProps }) => this.track(event, eventProps));
    }

    private initSessionReplay(screen?: string) {
        if (!this._isSessionReplayInitialized) {
            const isRightQuestionScreen =
                this._screenViewEventCount === INIT_SESSION_REPLAY_FROM_SCREEN_NUMBER;
            const screens = /(paywall)|(email)|(password)/g;
            const isRightFirstScreen = !!screen?.match(screens)?.length;

            if (isRightQuestionScreen || isRightFirstScreen) {
                this._instance?.add(sessionReplayTracking);
                this._isSessionReplayInitialized = true;
            }
        }
    }
}

// TODO: remove when content-type for PALTA_API will be changed
class AmplitudeInstanceOld extends Tracker {
    private readonly _name: string | undefined = undefined;
    private readonly _key: string | undefined = undefined;
    private readonly _apiEndpoint: string | undefined = undefined;

    private _propertiesQueue: EventProps = {};
    private _eventsQueue: { event: string; eventProps?: EventProps }[] = [];

    constructor(key: string, name?: string, apiEndpoint?: string) {
        super();

        this._key = key;
        this._name = name;
        this._apiEndpoint = apiEndpoint;
    }

    init() {
        if (this._key && !this._isInitialized) {
            amplitudeOld.getInstance(this._name).init(
                this._key,
                undefined,
                this._apiEndpoint
                    ? {
                          apiEndpoint: removeUrlProtocol(this._apiEndpoint),
                      }
                    : undefined
            );
            this._isInitialized = true;
            this.proceedPropertiesQueue();
            this.proceedEventsQueue();
        }

        if (this._isStoped) {
            this._isStoped = false;
        }
    }

    setAmplitudeProperties(props: EventProps) {
        const instance = amplitudeOld.getInstance(this._name);
        if (this._isInitialized && !this._isStoped) {
            instance.setUserProperties(props);
        } else {
            this._propertiesQueue = {
                ...this._propertiesQueue,
                ...props,
            };
        }
    }

    track(event: string, eventProps?: EventProps) {
        const instance = amplitudeOld.getInstance(this._name);
        if (this._isInitialized && !this._isStoped) {
            instance.logEvent(event, eventProps);
        } else {
            this._eventsQueue.push({ event, eventProps });
        }
    }

    setUserId(uuid: string) {
        amplitudeOld.getInstance(this._name).setUserId(uuid);
    }

    private proceedPropertiesQueue() {
        this.setAmplitudeProperties(this._propertiesQueue);
    }

    private proceedEventsQueue() {
        this._eventsQueue.forEach(({ event, eventProps }) => this.track(event, eventProps));
    }
}

export const AmplitudeTracker = new AmplitudeInstance(AMPLITUDE_KEY);
export const PaltaDataPlatformTracker = new AmplitudeInstanceOld(
    PALTA_DATA_PLATFORM_KEY,
    PALTA_DATA_PLATFORM_INSTANCE_NAME,
    PALTA_API
);
