import { useEffect } from "react";

export const preloadImages = (imgsSrcs: string[]) => {
    imgsSrcs.forEach((src) => {
        const image = new Image();
        image.src = src;
    });
};

const usePreloadImg = (imgsSrcs: string[]) => {
    useEffect(() => {
        preloadImages(imgsSrcs);
    }, []);
};

export default usePreloadImg;
