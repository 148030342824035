export enum ExperimentIds {
    // do not remove this is permanent exp for splitting users into segments
    SPLIT_INTO_TWO_SEGMENTS = "webtest_split_into_two_segments",
    NEW_PAYMENT_VERSION = "webtest-palta-new-sdk-2",
    DISABLE_PREVENT_DUPLICATE_FLOW = "disable_prevent_duplicate_flow",
    MALE_CALISTHENICS = "webtest_calisthenics_FAEX_495_jul24",
    USER_SUMMARY = "webtest_user_summary_FAEX-496_jul24",
    NUMBER_OF_STEPS = "webtest_number_of_step_FAEX_509_aug24",
    AI_LAB_UPSELL = "webtest_ai_lab_upsell_FAEX_563_oct24",
    NUTRITION_BOOK_ITER2 = "webtest_nutri_book_iter2_FAEX_565_oct24",
    DYNAMIC_PRICES = "webtest_dynamic_prices_iter1_FAEX_569_oct24",
    COMPARE_ZING_VS_COACH_ITER1 = "webtest_compare_zing_vs_coach_iter1_FAEX_568_oct24",
    YEARLY_PLAN = "webtest_yearlyplan_FAEX_567_oct24",
    LOCALISED_NUTRITION_BOOK = "webtest_localised_nutrition_book_FAEX_503",
    PRIORITIZING_PRICING = "webtest_prioritizing_pricing_over_product_details_FAEX_577_nov24",
    ANDROID_DISCLAIMER = "webtest_android_checkout_and_disclaimer_FAEX_578_nov24",
    GOAL_BASED_FUNNEL_WL = "webtest_goal_based_funnel_wl_FAEX_515_nov24",
    AI_USER_SUMMARY = "webtest_ai_summary_FAEX_505_nov24",
}

export const enum ExperimentVariants {
    CONTROL = "control",
    TEST = "test",
    TEST_1 = "test_1",
    TEST_2 = "test_2",
    TEST_3 = "test_3",
    NOT_ALLOCATED = "not_allocated",
    SEGMENT_A = "segment_A",
    SEGMENT_B = "segment_B",
}

export const TEST_VARIANTS = [
    ExperimentVariants.TEST,
    ExperimentVariants.TEST_1,
    ExperimentVariants.TEST_2,
    ExperimentVariants.TEST_3,
];

// need to track paywall id for analytics
export const paywallExperimentsIds = [
    ExperimentIds.COMPARE_ZING_VS_COACH_ITER1,
    ExperimentIds.YEARLY_PLAN,
    ExperimentIds.PRIORITIZING_PRICING,
    ExperimentIds.ANDROID_DISCLAIMER,
];
